.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  font-family: urbanist, sans-serif !important;
  font-size: .9375rem;
  line-height: 1.6;
  color: #7e7e7e;
  text-align: left;
  background-color: #fff;
}

.modal-backdrop {
  opacity: 0.8 !important;
}

strong {
  font-weight: 800;
}

.open-san {
  font-family: urbanist, sans-serif;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.cart-canvas {
  width: 450px !important;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
}

a {
  transition: all .2s;
}

.cart-canvas .offcanvas-header {
  align-items: start;
}

.h-115 {
  height: 115px;
}

.h-40 {
  height: 40px;
}

.w-115 {
  width: 115px;
}

.w-90 {
  width: 90px !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.625rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.625rem !important;
}

.mt-6,
.my-6 {
  margin-top: 1.875rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 1.875rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 1.875rem !important;
}

.mt-8,
.my-8 {
  margin-top: 3.125rem !important;
}

.bg-black {
  background-color: #000000;
}

.main-header .normal-logo,
.main-header .normal-button-icon {
  display: inline-block;
}

.main-header .sticky-logo,
.main-header .sticky-button-icon {
  display: none;
}

.para-text p {
  text-align: justify;
  font-family: CormorantGaramond-SemiBold;
  color: #000000;
  font-size: 16px;
}

.big-text {
  font-size: 100px;
  position: relative;
  top: 0px;
  line-height: 0;
}

img {
  max-width: 100%;
  height: auto;
}

img {
  vertical-align: middle;
  border-style: none;
}

.position-relative {
  position: relative !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

#emailid {
  height: 50px;
}

.w-60px {
  width: 60px !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.gray-bg {
  background-color: gray !important;
  border-color: gray !important;
}

.rounded-left {
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.no-rounded-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.z-index-2 {
  z-index: 2 !important;
}

.text-white-link {
  color: #ffffff !important;
  font-family: urbanist, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.text-black-link {
  color: #000000;
  font-size: 26px;
}

.scrollable-price-area {
  overflow-y: auto;
  max-height: 290px;
}

.price-section {
  width: 33%;
  text-align: right;
}

.form-control {
  background-color: #f5f5f5;
  border-radius: 3px;
  box-shadow: none;
  padding: 0.6875rem 1.25rem;
  font-weight: 500;
}

.form-control:focus {
  background-color: #f5f5f5;
  border-color: #000;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.unavilable-product {
  opacity: 0.7;
}

.out-of-stock-sec {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 15px;
  text-align: center;
  background: white;
  font-size: 18px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.5);
  border: 1px solid #ccc;
  color: #000;
}

.btn-secondary {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: none;
  border-radius: 0;
  font-weight: 600;
}

.btn {
  padding: 0.625rem 1.875rem;
}

.ml-02r {
  margin-left: 0.2rem;
}

.place-order-sec {
  border-top: 1px solid #f0f0f0;
  background: #fff;
  padding: 16px 22px;
  box-shadow: 0 -2px 20px 0 rgb(0 0 0 / 10%);
}

.place-order-btn {
  display: block;
  margin: auto;
  margin-right: 0;
}

.edit-add {
  background-color: #2196f3;
  padding: 5px 10px;
  color: #ffffff;
  border-radius: 2px;
  font-weight: 600;
  cursor: pointer;
}

.address-section {
  overflow-y: auto;
  max-height: 375px;
}

.ml-1r {
  margin-left: 1rem;
}

.address-modal-header .btn-close {
  position: absolute;
  right: 20px;
  top: 25px;
}

.lh-175 {
  line-height: 1.75 !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.w-100 {
  width: 100% !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: end !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.margin-0-1 {
  margin: 0 1rem;
}

.mb-6,
.my-6 {
  margin-bottom: 1.875rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 2.5rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 3.125rem !important;
}

.mb-9,
.my-9 {
  margin-bottom: 3.75rem !important;
}

.mb-10,
.my-10 {
  margin-bottom: 4.375rem !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.pl-2,
.px-2 {
  padding-left: 0.625rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.625rem !important;
}

.pl-6,
.px-6 {
  padding-left: 1.875rem !important;
}

.pr-6,
.px-6 {
  padding-right: 1.875rem !important;
}

.pl-7,
.px-7 {
  padding-left: 2.5rem !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pr-7,
.px-7 {
  padding-right: 2.5rem !important;
}

.pl-9,
.px-9 {
  padding-left: 3.75rem !important;
}

.pr-9,
.px-9 {
  padding-right: 3.75rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.625rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.25rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.25rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 1.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 1.5rem !important;
}

.pt-7,
.py-7 {
  padding-top: 2.5rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 3.125rem !important;
}

.pt-8,
.py-8 {
  padding-top: 3.125rem !important;
}

.pt-11,
.py-11 {
  padding-top: 5rem !important;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.d-block {
  display: block !important;
}

.w-50 {
  width: 50% !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.625rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.625rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.625rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.625rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.25rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.25rem !important;
}

.text-body {
  color: #7e7e7e !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pt-1,
.py-1 {
  padding-top: 0.3125rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pb-9,
.py-9 {
  padding-bottom: 3.75rem !important;
}

.pt-9,
.py-9 {
  padding-top: 3.75rem !important;
}

.pb-10,
.py-10 {
  padding-bottom: 4.375rem !important;
}

.pb-11,
.py-11 {
  padding-bottom: 5rem !important;
}

.pt-10,
.py-10 {
  padding-top: 4.375rem !important;
}

.pb-13,
.py-13 {
  padding-bottom: 6.25rem !important;
}

.pt-13,
.py-13 {
  padding-top: 6.25rem !important;
}

.d-none {
  display: none !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-freightbig-normal {
  font-family: FreightBigCmpPro;
  font-weight: 500 !important;
}

.font-garamond-Regular {
  font-family: CormorantGaramond-Regular;
}

.font-garamond-semiBold {
  font-family: CormorantGaramond-SemiBold;
}

.sastainbility {
  font-size: 80px;
  color: #000;
}

.bg-gray-2 {
  background-color: #f5f5f5;
}

.mw-460 {
  max-width: 460px;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 0;
  margin-bottom: 0;
  font-size: .9375rem;
  font-weight: 600;
  list-style: none;
  background-color: transparent;
  border-radius: 3px;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 1rem;
}

.breadcrumb-item.active {
  color: #000;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 1rem;
  color: #b2b2b2;
  content: "/";
}

.breadcrumb-site .breadcrumb-item+.breadcrumb-item::before {
  content: "";
  margin: 0 15px;
  color: #9b9b9b;
  font-size: 0;
  width: 20px;
  height: 1px;
  background-color: currentColor;
}

.btn-block {
  display: block;
  width: 100%;
}

.color-black {
  color: #000 !important;
}

.text-left {
  text-align: left !important;
}

.letter-spacing-01 {
  letter-spacing: .1em !important;
}

.fw-600 {
  font-weight: 600;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.carousel-item {
  text-align: center;
}

.carousel-indicators {
  bottom: -5rem;
}

.carousel-indicators [data-bs-target] {
  background-color: #000 !important;
  width: 15px !important;
}

.carousel-control-next,
.carousel-control-prev {
  filter: invert(100%);
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 3rem;
  height: 3rem;
}

.carousel-control-prev {
  left: -8rem;
  right: auto;
}

.carousel-control-next {
  right: -8rem;
  left: auto;
}

.first-block-aboutus {
  position: absolute;
  top: 0;
  padding-top: 28em;
  width: 25%;
  right: 4%;
  color: #fff;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

.animation-firstBlock {
  animation: smooth-appear1 1s ease forwards;
}

@keyframes smooth-appear1 {
  from {
    padding-top: 30em;
  }

  to {
    padding-top: 25em;
  }
}

.second-block-aboutus {
  position: absolute;
  top: 0;
  padding-top: 65em;
  width: 45%;
  right: 4%;
  color: #fff;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

.animation-secondBlock {
  animation: smooth-appear2 1s ease forwards;
}

@keyframes smooth-appear2 {
  from {
    padding-top: 65em;
  }

  to {
    padding-top: 60em;
  }
}

.third-block-aboutus {
  position: absolute;
  bottom: 0;
  padding-bottom: 18em;
  width: 45%;
  right: 20%;
  color: #fff;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}


.animation-thirdBlock {
  animation: smooth-appear3 1s ease forwards;
}

@keyframes smooth-appear3 {
  from {
    padding-bottom: 18em;
  }

  to {
    padding-bottom: 23em;
  }
}

.fourth-block-aboutus {
  position: absolute;
  bottom: 0;
  padding-bottom: 0;
  width: 45%;
  right: 20%;
  color: #fff;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}


.animation-fourthBlock {
  animation: smooth-appear4 1s ease forwards;
}

@keyframes smooth-appear4 {
  from {
    padding-bottom: 0;
  }

  to {
    padding-bottom: 3em;
  }
}

.fifth-block-aboutus {
  position: absolute;
  top: 0;
  padding-top: 13em;
  width: 25%;
  left: 3%;
  color: #fff;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}


.animation-fifthBlock {
  animation: smooth-appear5 1s ease forwards;
}

@keyframes smooth-appear5 {
  from {
    padding-top: 13em;
  }

  to {
    padding-top: 8em;
  }
}

.sixth-block-aboutus {
  position: absolute;
  top: 0;
  padding-top: 45em;
  width: 25%;
  left: 3%;
  color: #fff;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}


.animation-sixthBlock {
  animation: smooth-appear6 1s ease forwards;
}

@keyframes smooth-appear6 {
  from {
    padding-top: 45em;
  }

  to {
    padding-top: 40em;
  }
}

.seventh-block-aboutus {
  position: absolute;
  top: 0;
  padding-top: 33em;
  width: 25%;
  right: 3%;
  color: #fff;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}


.animation-seventhBlock {
  animation: smooth-appear7 1s ease forwards;
}

@keyframes smooth-appear7 {
  from {
    padding-top: 33em;
  }

  to {
    padding-top: 28em;
  }
}

.aboutus-header {
  font: 3vw/1.2 "FreightBigCmpPro";
  line-height: 3rem;
}

.aboutus-desc {
  font: 1.5vw/1.2 "OpenSansConsolidated";
  line-height: 2rem;
}

.text-justify {
  text-align: justify !important;
}

.h-48px {
  height: 48px !important;
}

.w-48px {
  width: 48px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-42 {
  font-size: 42px !important;
}

.fs-44 {
  font-size: 44px !important;
}

.fs-48 {
  font-size: 48px !important;
}

.gtf-back-to-top.in {
  opacity: 1;
  position: fixed;
}

.gtf-back-to-top {
  opacity: 0;
  transition: opacity .5s;
  bottom: 1rem;
  right: 1rem;
}

a.bg-hover-primary:hover {
  background-color: #1f201f !important;
}

a.hover-white:hover {
  color: #fff !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.lh-13 {
  line-height: 1.3 !important;
}

.fs-18 {
  font-size: 18px !important;
}

.main-header .navbar .nav-link svg {
  font-size: 28px;
}

svg.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: 0;
}

.navbar-toggler,
.toggle-bar {
  width: 28px;
  height: 28px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, .1);
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 3px;
}

.border-0 {
  border: none !important;
}

._container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  /* width: 100%; */
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.main-header .main-menu .nav-item {
  transition: all .5s;
}

.fs-16 {
  font-size: 16px !important;
}

.header-hidden {
  -webkit-transform: translate(0, -100%);
  transform: translate(0, -100%);
  box-shadow: none !important;
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .nav-link::before,
.navbar-dark .dropdown-header::before {
  background-color: #fff !important;
}

.main-header .main-menu .nav-link::before,
.main-header .main-menu .dropdown-header::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 1px;
  background-color: #000;
  transition: width .5s;
}

.main-header .main-menu .nav-item .nav-link::before {
  width: 0;
}

.nav-link::after {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: white;
  transition: width .5s;
  bottom: -6px;
  position: relative;
}

.nav-link:hover:not(.header-icon)::after {
  width: 100%;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

[data-animate] {
  opacity: 0;
}

[data-animate].animated {
  opacity: 1;
}

.hover-zoom-in {
  overflow: hidden;
}

.hover-shine {
  position: relative;
  overflow: hidden;
}

.hover-zoom-in img,
.hover-zoom-in .img,
.hover-zoom-in .card-img {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: .5s ease-in-out;
}

.hover-zoom-in:hover img,
.hover-zoom-in:hover .img,
.hover-zoom-in:hover .card-img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.hover-shine::before {
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
}

.hover-shine:hover::before {
  -webkit-animation: shine 1s;
  animation: shine 1s;
}

.navbar-light .sticky .sticky-area {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  background-color: #000000;
}

.size-med {
  font-size: 28px;
  color: #7e7e7e;
}

.pos-fixed-bottom-right {
  bottom: 0;
  right: 0;
}

.loading-doc {
  display: flex;
  margin: 0 auto;
  margin-top: 6rem;
}

.text-align-center {
  text-align: center;
}

.p-6 {
  padding: 1.875rem !important;
}

.z-index-10 {
  z-index: 10 !important;
}

.v-middle {
  vertical-align: middle;
  display: flex;
}

.slick-slide {
  height: inherit;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px !important;
  font-family: "Font Awesome\ 5 Free" !important;
  font-weight: 600 !important;
}

.slick-prev:before {
  content: "\f053" !important;
}

.slick-next:before {
  content: "\f054" !important;
}

.products-slider .slick-arrow {
  top: 32% !important;
}

/* .products-slider {
  padding: 0 10px;
} */

.imgs-slider .slick-next,
.imgs-slider .slick-prev {
  color: #fff !important;
  background: #000 !important;
  padding: 5px !important;
  width: 30px !important;
  height: 30px !important;
}

.testimonials-slider .slick-next,
.testimonials-slider .slick-prev {
  color: #000 !important;
  background: #fff !important;
  padding: 5px !important;
  width: 30px !important;
  height: 30px !important;
}

.imgs-slider .slick-prev {
  left: -18px;
}

.imgs-slider .slick-next {
  right: -18px;
}

.sketches .slick-slide {
  padding: 0 !important;
}

.company-imgs .slick-prev {
  left: -8px;
}

.company-imgs .slick-next {
  right: -8px;
}

.company-imgs .slick-slide:not(:first-child) {
  padding-left: 0 !important;
}

.menu-checkout-progress-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
}

.testimonial .slick-next {
  right: -18px;
}

.testimonial .slick-prev {
  left: -18px;
}

.testimonial .slick-next:before,
.testimonial .slick-prev:before {
  background: white;
  color: black;
  padding: 5px;
  opacity: 1;
  font-size: 15px !important;
}

.w-179px {
  width: 179px;
}

.link-bg:hover {
  color: #000;
}

.card {
  background-color: transparent !important;
}

.abt-img-height {
  min-height: 326px;
  max-height: 326px;
}

video {
  -webkit-filter: brightness(108.5%);
}

.progress-step {
  font-size: 1.29rem;
  letter-spacing: .1em;
  text-transform: uppercase;
  letter-spacing: 0;
  margin-right: 28px;
  position: relative;
  padding: 12px 0;
  color: #000;
  font-weight: 700;
  line-height: 1;
}

.accordion-button::after {
  background-image: var(--bs-accordion-btn-icon) !important;
}

.menu-horz-inline>li {
  display: inline-block;
}

.progress-step:after {
  content: "";
  position: absolute;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  bottom: 2px;
  left: 0;
  right: 0;
}

.progress-step.active:after {
  border-bottom-color: #000;
}

.progress-step.inactive {
  font-weight: 400;
}

.menu-checkout-progress {
  padding-left: 0;
}

/* .slick-slide img {
  height: 630px;
} */

.about-img img {
  height: 400px !important;
}

.about-us-first-notes {
  position: absolute;
  right: 5%;
  top: 65vh;
  width: 25%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.about-us-second-notes {
  position: absolute;
  left: 5%;
  top: 30%;
  width: 45%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.about-us-title {
  display: flex;
  position: absolute;
  top: 15px;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  color: #fff;
  font-family: "FreightBigCmpPro";
  text-transform: uppercase;
  line-height: 6rem;
}

.about-us-title .title-first {
  font-size: 5vw;
  display: flex;
  justify-content: start;
  margin-left: 2%;
}

.about-us-title .vw-15 {
  font-size: 15vw;
  align-items: center;
  justify-content: center;
  display: flex;
}

.about-us-first-notes .parent-block li,
.about-us-second-notes .parent-block li {
  display: flex;
}

.about-us-first-notes .fw-600,
.about-us-second-notes .fw-600 {
  font: 3vw/1.2 "FreightBigCmpPro";
  line-height: 3rem;
}

.about-us-first-notes li:not(.fw-600),
.about-us-second-notes li:not(.fw-600) {
  margin-bottom: 3rem;
  font: 1.5vw/1.2 "OpenSansConsolidated";
  line-height: 2rem;
}

.about-us-first-notes li span,
.about-us-second-notes li span {
  display: block;
  transform: translateY(200%);
  margin: 0.05rem;
}

.about-us-first-notes li span.active,
.about-us-second-notes li span.active {
  transform: translateY(0%);
  transition: 1s ease-in-out;
}

.slick-slider img {
  max-width: 100%;
}

.slick-slide img {
  display: block;
  width: 100%;
  object-fit: cover;
  height: 630px;
}

.slick-slide video {
  display: block;
  width: 100%;
  height: 630px;
}

.products-slider img {
  height: 300px;
}

.slick-vertical {
  height: auto;
}

.pos-fixed-left-center {
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 0;
  background: transparent;
}

.pos-fixed-right-center {
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 0;
  background: transparent;
}

.section-header {
  font-weight: 400;
  letter-spacing: .1em;
  font-size: 1.29rem;
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0;
  font-size: .93rem;
  font-weight: 700;
  margin: 0 0 25px;
  padding: 26px 0;
  margin-bottom: 25px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.email-note {
  color: #616365;
  margin: -10px 0 30px;
}

.badge-primary {
  color: #fff;
  background-color: #1f201f;
  border-radius: 3px;
}

.galleries-product .slider-nav img,
.galleries-product .slider-nav video {
  height: 120px !important;
}

.model-padding {
  padding-top: 0 !important;
}

.site-wrapper {
  max-width: 1500px;
  padding-left: 80px;
  padding-right: 80px;
}

.checkout-img {
  height: 440px;
  object-fit: cover;
}

.couponsForm-base-applyButton {
  position: relative;
  top: -2.2rem;
  right: 8px;
  font-size: 14px;
  color: black;
  font-weight: 700;
  letter-spacing: 1px;
  float: right;
  cursor: pointer;
}

.about-us-section {
  margin-top: 90px;
}

.delivery-address .css-13cymwt-control,
.delivery-address .css-t3ipsp-control {
  border: 0 !important;
  background-color: #f5f5f5 !important;
  padding: 0.38rem;
}

.delivery-address .css-t3ipsp-control {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%)
}

.req-hidden-field {
  position: absolute;
  opacity: 0;
  width: 0;
}

@media (min-width: 200px) {

  .mh-xl-640 {
    height: 350px;
  }
}


@media (max-width: 375px) {

  .back-to-top {
    padding-right: 60px !important;
  }

}

@media (max-width: 576px) {

  .nav-link {
    font-size: 14px !important;
  }

  .mob-d-none {
    display: none;
  }

  .mob-mb {
    margin-bottom: -1px;
  }

  .mob-mt {
    margin-top: -1px;
  }

  .checkout-img {
    height: 260px;
  }

  .abt-img-height {
    min-height: 160px;
    max-height: 160px;
  }

  .out-of-stock-sec {
    padding: 4px;
    font-size: 12px;
  }

  .imgs-slider .slick-prev {
    left: -30px;
  }

  .imgs-slider .slick-next {
    right: -30px;
  }

  .site-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }

  .progress-step {
    font-size: 0.8rem;
  }

  .place-order-btn {
    font-size: 12px;
  }

  .mob-none {
    font-size: 20px;
  }

  .mob-none span {
    display: none;
  }

  #discountBanner p {
    font-size: 10px !important;
    padding-top: 4px !important;
  }

  .close-banner {
    top: -2px !important;
  }

  .product-details-layout-5 {
    margin-top: 20px !important;
  }

  .insta-imgs .slick-prev {
    left: -8px;
  }

  .insta-imgs .slick-next {
    right: -8px;
  }

  .about-us-first-notes {
    top: 18vh;
    width: 100%;
    height: 70vh;
    font: 4vw/1.2 "FreightBigCmpPro";
  }

  .about-us-second-notes {
    top: 5%;
    left: 2%;
    width: 98%;
    height: 70vh;
    font: 4vw/1.2 "FreightBigCmpPro";
  }

  .about-us-first-notes li span,
  .about-us-second-notes li span {
    line-height: 2rem;
  }

  .about-us-first-notes li:not(.fw-600),
  .about-us-second-notes li:not(.fw-600) {
    margin-bottom: 1.5rem;
  }

  .mobile-p-0 {
    padding-top: 0 !important;
  }

  .about-us-title {
    top: 40px;
    line-height: 1.5rem;
    left: 6px;
  }

  .aboutus-header {
    line-height: 1rem;
    margin-bottom: 3px;
  }

  .aboutus-desc {
    line-height: 1rem;
  }

  .first-block-aboutus {
    padding-top: 15em;
    width: 30%;
  }

  .second-block-aboutus {
    padding-top: 20em;
  }

  .third-block-aboutus {
    padding-bottom: 1em;
    right: 25%;
  }

  .fourth-block-aboutus {
    padding-bottom: -5em;
    right: 25%;
  }

  .fifth-block-aboutus {
    padding-top: 6em;
  }

  .sixth-block-aboutus {
    padding-top: 16em;
  }

  .seventh-block-aboutus {
    padding-top: 13em;
  }

  .animation-firstBlock {
    animation: xs-smooth-appear1 1s ease forwards;
  }

  .animation-secondBlock {
    animation: xs-smooth-appear2 1s ease forwards;
  }

  .animation-thirdBlock {
    animation: xs-smooth-appear3 1s ease forwards;
  }

  .animation-fourthBlock {
    animation: xs-smooth-appear4 1s ease forwards;
  }

  .animation-fifthBlock {
    animation: xs-smooth-appear5 1s ease forwards;
  }

  .animation-sixthBlock {
    animation: xs-smooth-appear6 1s ease forwards;
  }

  .animation-seventhBlock {
    animation: xs-smooth-appear7 1s ease forwards;
  }

  @keyframes xs-smooth-appear1 {
    from {
      padding-top: 15em;
    }

    to {
      padding-top: 10em;
    }
  }

  @keyframes xs-smooth-appear2 {
    from {
      padding-top: 23em;
    }

    to {
      padding-top: 18em;
    }
  }

  @keyframes xs-smooth-appear3 {
    from {
      padding-bottom: 1em;
    }

    to {
      padding-bottom: 6em;
    }
  }

  @keyframes xs-smooth-appear4 {
    from {
      padding-bottom: -5em;
    }

    to {
      padding-bottom: 0em;
    }
  }

  @keyframes xs-smooth-appear5 {
    from {
      padding-top: 6em;
    }

    to {
      padding-top: 1em;
    }
  }

  @keyframes xs-smooth-appear6 {
    from {
      padding-top: 16em;
    }

    to {
      padding-top: 11em;
    }
  }

  @keyframes xs-smooth-appear7 {
    from {
      padding-top: 13em;
    }

    to {
      padding-top: 8em;
    }
  }
}

@media (min-width: 576px) {

  ._container,
  .container-sm {
    max-width: 546px;
  }

  .mh-xl-640 {
    height: 300px;
  }
}

@media (min-width: 576px) {

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
}

@media (min-width: 768px) {

  ._container,
  .container-sm,
  .container-md {
    max-width: 738px;
  }
}

@media (min-width: 768px) {
  .text-md-right {
    text-align: right !important;
  }
}

@media (min-width: 768px) {

  .pt-md-18,
  .py-md-18 {
    padding-top: 13.75rem !important;
  }
}

@media only screen and (min-width: 768px) {
  .section-header {
    font-size: .86rem;
    text-align: left;
  }
}

@media (min-width: 768px) {

  .pb-md-18,
  .py-md-18 {
    padding-bottom: 13.75rem !important;
  }
}

@media (min-width: 768px) {

  .mb-md-6,
  .my-md-6 {
    margin-bottom: 1.875rem !important;
  }

  .mh-xl-640 {
    height: 440px;
  }

  .login-detail-wrapper {
    min-height: 608px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px),
only screen and (min-width: 1025px) {
  .menu-checkout-progress-wrapper {
    height: 112px;
  }

  .menu-checkout-progress-wrapper .menu-checkout-progress {
    margin-bottom: 0;
    font-size: 0;
  }

  .product-details-layout-5 {
    margin-top: 50px !important;
  }
}

@media (max-width: 800px) {
  .sastainbility {
    font-size: 52px;
  }

  /* .slick-prev:before,
  .slick-next:before {
    display: none;
  } */

  .cart-canvas {
    width: 340px !important;
  }
}

@media (max-width: 991px) {
  .header-logo-sec {
    display: none;
  }

  .contact-details-update {
    border-top: 1px solid #ccc;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    border-bottom: 1px solid #ccc;
  }

  .social-contact .mail-contact-footer-dev:first-child {
    border-top: 1px solid #ccc;
  }

  .mobile-pl-2 {
    padding-left: 0.625rem !important;
  }

  .try-btn-beauty {
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {

  ._container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 962px;
  }
}

@media (min-width: 992px) {

  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 6.25rem !important;
  }
}

@media (min-width: 992px) {

  .pt-lg-13,
  .py-lg-13 {
    padding-top: 6.25rem !important;
  }
}

@media (min-width: 992px) {

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 992px) {

  .pt-lg-13,
  .py-lg-13 {
    padding-top: 6.25rem !important;
  }
}

@media (min-width: 992px) {

  .pt-lg-14,
  .py-lg-14 {
    padding-top: 6.875rem !important;
  }
}

@media (min-width: 992px) {

  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 3.625rem !important;
  }

  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 5.625rem !important;
  }
}

@media (min-width: 992px) {

  .mt-lg-16,
  .my-lg-16 {
    margin-top: 9.375rem !important;
  }
}

@media (min-width: 992px) {

  .pb-lg-17,
  .py-lg-17 {
    padding-bottom: 10.625rem !important;
  }
}

@media (min-width: 992px) {

  .pt-lg-17,
  .py-lg-17 {
    padding-top: 10.625rem !important;
  }
}

@media (min-width: 992px) {
  .mw-lg-570 {
    max-width: 570px;
  }
}

@media (min-width: 992px) {

  .pt-lg-16,
  .py-lg-16 {
    padding-top: 8.875rem !important;
  }
}

@media (max-width: 992px) {

  .fs-18 {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 1199px) {

  .about-us-section {
    margin-top: 60px;
  }
}

@media (min-width: 1200px) {

  ._container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 100%;
  }
}

@media screen and (min-width: 1200px) {

  .container-xxl {
    max-width: 1850px;
  }
}

@media (min-width: 1200px) {
  .d-xl-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

@media (min-width: 1200px) {

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -1.5rem !important;
  }
}

@media (min-width: 1200px) {

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -1.5rem !important;
  }
}


@media (min-width: 1200px) {

  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 2.7rem !important;
  }
}

@media (min-width: 1200px) {

  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2rem !important;
  }

  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2rem !important;
  }
}

@media (min-width: 1200px) {

  .pt-xl-7,
  .py-xl-7 {
    padding-top: 2.7rem !important;
  }
}

@media (min-width: 1200px) {

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 1.2rem !important;
  }
}

@media (min-width: 1200px) {

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 1.2rem !important;
  }
}

@media (min-width: 1200px) {

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
}

@media (min-width: 990px) {
  .d-xl-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media (min-width: 1200px) {
  .mh-xl-640 {
    height: 640px;
  }
}

@media (min-width: 1200px) {

  .pl-xl-13,
  .px-xl-13 {
    padding-left: 6.25rem !important;
  }
}


@media (min-width: 1200px) {

  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 3.75rem !important;
  }
}

@media (min-width: 1200px) {
  .galleries-product-01 .slider-nav {
    -ms-flex-order: 1;
    order: 1;
    width: 84px;
  }
}

@media (min-width: 1200px) {
  .galleries-product-01 .slider-for {
    -ms-flex-order: 2;
    order: 2;
    width: calc(100% - 84px);
  }

  .single-img .slider-for {
    width: 100%;
  }
}

@media (min-width: 1200px) {

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
}

@media (min-width: 1200px) {

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
}

@media (min-width: 1200px) {

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
}

@media (min-width: 1200px) {

  .pr-xl-9,
  .px-xl-9 {
    padding-right: 3.75rem !important;
  }
}

@media screen and (min-width: 1440px) {
  div.container-xl {
    max-width: 1440px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes fadeInUp {
  from {
    transform: translate3d(0, 100%, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 100%, 0)
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}