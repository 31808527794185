.journal-icon {
    width: 80px;
    margin: 0 auto 10px;
    text-align: center;
    display: block;
}

.news-section {
    padding: 40px 0 100px 0 !important;
}

.journal-section {
    padding: 50px 0 100px 0 !important;
}

.creative-para {
    text-align: center;
    color: #000000;
}

.blog-bottom-margin {
    margin-bottom: 30px;
}

.media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}

.left-blog-img img {
    width: 200px;
    height: 150px;
    object-fit: cover;
}

.media-body {
    -ms-flex: 1;
    flex: 1;
}

.text-decorated:hover {
    color: #1f201f;
    text-decoration: underline;
}

.blog-margin {
    margin-top: 30px;
}

.three-blog-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.journal-desc img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    margin-top: 30px;
}

.journal-desc h2 {
    font-size: 38px !important;
    color: #000 !important;
    font-family: FreightBigCmpPro;
    font-weight: 500 !important;
}

.journal-desc h2:first-child {
    text-align: center;
    font-size: 48px !important;
}

.go-back-journal {
    position: absolute;
    color: #000;
    font-size: 1.8rem;
    cursor: pointer;
}

.journal-desc h2 a:hover {
    text-decoration: underline;
    color: #000;
}

@media (max-width: 576px) {
    .journal-desc h2:first-child {
        font-size: 40px !important;
    }
}