.contact-img {
    height: 450px;
    object-fit: cover;
}

.thankyou-img {
    height: 650px;
    object-fit: cover;
    margin: 0 auto;
    display: block;
}

.man-clipart {
    width: 150px;
    display: block;
    margin: 0 auto;
}

.contact-details-update {
    text-align: center;
    color: #000000;
    padding: 4px 40px;
}

.contact-details-update p {
    font-size: 16px;
    margin: 0;
    margin-bottom: 10px;
}

.contact-details-update a {
    text-decoration: underline;
    font-weight: 700;
    margin-bottom: 20px !important;
    display: block;
}

.mail-contact-footer-dev {
    padding: 30px 30px;
}

.mail-contact-footer-dev h2 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 30px;
    font-style: italic;
    font-family: FreightBigCmpPro;
    font-weight: 500;
    color: #000;
}

.mail-contact-footer-dev ul {
    margin: 0 auto;
    display: block;
    text-align: center;
    margin-left: 0 !important;
}

.border-contact {
    border-right: 1px solid gray;
    border-left: 1px solid gray;
    padding: 30px;
}

.btn-contact-journal {
    padding: 12px 20px;
    background-color: black;
    border: none;
    outline: none;
    color: #ffffff !important;
    margin: 0 auto;
    display: block;
    text-align: center;
    width: 140px;
    position: relative;
    top: 2px;
}

.border-contact button {
    padding: 0 8px;
    background-color: black !important;
    border: none;
    outline: none;
}

.cursor-pointer {
    cursor: pointer;
}

.font-italic {
    font-style: italic;
}

.contact-us-letter {
    text-align: center;
    font-size: 1rem;
    padding: 0 2rem;
    color: #000;
    margin: auto;
    font-family: "OpenSansConsolidated";
}

@media (max-width: 800px) {
    .border-contact {
        border: none;
        padding: 30px 10px;
    }
}

@media (max-width: 576px) {
    .contact-us-letter {
        font-size: 1rem;
    }
}

