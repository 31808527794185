.h-30 {
    height: 30px;
}

.pos-relative {
    position: relative;
}

.w-550 {
    width: 550px;
}

.w-90p {
    width: 90%;
}

.sample-img {
    width: 100% !important;
    object-fit: cover;
    min-height: 280px !important;
}

.product-desc p {
    word-wrap: break-word;
}

.add-sample-cart {
    margin-top: 0.5rem !important;
}

.sample-desc {
    margin-left: -13px;
    margin-right: -13px;
    margin-bottom: 2.5rem;
    text-align: center;
}

.h-80 {
    height: 80%;
}

@media (max-width: 576px) {
    .img-minh {
        min-height: 125px !important;
    }

    .add-sample-cart {
        position: absolute;
        left: 0;
        margin-top: 20px !important;
    }
    .mob-p-0 {
        padding: 0;
    }

    .sup-img-minh {
        min-height: 200px !important;
    }
}