.sticky-area-wrap {
    position: relative;
    transition: all .5s;
    z-index: 1000;
}

.sticky-area {
    width: 100%;
}

#offcanvasNavbar-expand.offcanvas.offcanvas-start {
    width: 85%;
    background: #000;
}

.shop-img {
    /* margin-top: 30px; */
}

.top-padding {
    padding: 120px 0 100px 0 !important;
}

.sticky-area {
    transition: all .5s;
    height: auto;
    background-color: transparent;
}

.mobile-view {
    display: none;
}

.mobile-view-padding {
    padding-left: 5px !important;
}

.ml-5 {
    margin-left: 2rem;
}

._mb-6 {
    margin-bottom: 5rem;
}

.p-10 {
    padding: 4.875rem !important;
}

.p-12 {
    padding: 6.875rem !important;
}

.product-title {
    position: absolute;
    color: #fff;
    font-size: 2.7rem;
    width: 100%;
    opacity: 1;
    top: 2rem;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

.product-title span {
    text-align: center;
}

.subtitle {
    position: absolute;
    left: 0;
    bottom: 2rem;
    padding: 0;
    text-align: center;
    color: #fff;
    font-size: 1.5rem;
    font-family: "OpenSansConsolidated";
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

.btn-signup {
    color: #000;
    background-color: transparent;
    border-color: #000;
    box-shadow: none;
    border-radius: 0;
    font-weight: 600;
    margin-top: 9.9rem;
}

.btn-continue {
    color: #000;
    background-color: transparent;
    border-color: #000;
    box-shadow: none;
    border-radius: 0;
    font-weight: 600;
    margin: 1rem 0;
}

.btn-signup:hover,
.btn-continue:hover {
    color: #fff;
    background-color: rgb(133, 132, 132);
}

.reset-btn {
    margin-top: 5.3rem;
}

.text-uppercase {
    text-transform: uppercase;
}

.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.couponsForm-base-textInput {
    box-shadow: 0 0 5px #887191;
    margin: 5px 1px 3px 0px;
    border: 1px solid #887191;
}

.main-header .navbar {
    padding-top: 0;
    padding-bottom: 0;
    position: static;
}

.navbar-dark .nav-search {
    color: #fff !important;
}

.search-transparent {
    color: transparent;
}

.navbar-nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff !important;
}

.nav-link {
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: .1em;
    text-transform: uppercase;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.powered-logo {
    width: 25%;
}

.power-text {
    margin-top: 15px;
    margin-left: 15px;
}

.main-header .navbar-brand {
    margin: 0 55px !important;
}

.main-header .navbar-brand {
    width: 179px;
}

.navbar-dark .navbar-right .number {
    background-color: #fff !important;
    color: #000 !important;
}

.main-header .navbar-right .number {
    top: 0;
    right: 10px;
    font-size: 13px;
    background-color: #000;
    height: 18px;
    width: 18px;
    color: #fff;
    line-height: 18px;
    border-radius: 50%;
    text-align: center;
}

.header-icon {
    font-size: 28px !important;
}

.nav-toggle-btn {
    box-shadow: none !important;
    border: 0 !important;
}

/* 
.reveal {
    opacity: 0;
    transition: opacity 1s ease-out;
}

.reveal.show {
    opacity: 1;
} */

.sign-in .modal-dialog {
    margin: auto;
    top: 50%;
}

.sign-in.show .modal-dialog {
    -webkit-transform: translateY(-50%) !important;
    transform: translateY(-50%) !important;
}

.sign-in .nav-link {
    text-transform: none;
}

.posr-35 {
    position: relative;
    right: 35px;
}

.mr-5 {
    margin-right: 2rem;
}

.width-100 {
    width: 100%;
}

.form-border {
    /* border-bottom: 1px solid #ccc; */
    border-top: 1px solid #ccc;
}

.address-type {
    text-transform: uppercase;
    font-size: 11px;
    color: #444444;
    vertical-align: middle;
    padding: 4px 7px;
    border-radius: 2px;
    background-color: #f0f0f0;
    font-weight: 500;
    margin-right: 15px;
}

.toggle-icon {
    bottom: 0;
    display: block;
    height: 2px;
    left: 2px;
    right: 0;
    margin: auto;
    position: absolute;
    top: 0;
    transition: all .3s;
    background-color: currentColor;
}

.toggle-icon::before,
.toggle-icon::after {
    content: "";
    height: 2px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    transition: top .3s ease-in-out, background .3s, -webkit-transform .3s ease-in-out;
    transition: top .3s ease-in-out, transform .3s ease-in-out, background .3s;
    transition: top .3s ease-in-out, transform .3s ease-in-out, background .3s, -webkit-transform .3s ease-in-out;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    background-color: currentColor;
}

.toggle-icon::before {
    width: 18px;
    left: -6px;
    top: 8px;
}

.toggle-icon::after {
    top: -8px;
    left: -9px;
    width: 15px;
}

.remember-checkbox {
    position: relative;
    top: 2px;
    margin-right: 5px;
}

.tab-pane {
    padding: 2rem;
}

#nav-forgot-tab:hover {
    text-decoration: underline;
}

.nav-link {
    color: #495057;
}

.nav-link.active {
    color: #000;
}

.sign-in .modal-header {
    border-bottom: 0;
    padding: 1rem 1rem 0 1rem;
}

.cart-body {
    border-top: 1px solid rgba(0, 0, 0, .125);
}

.radio-btn-pos {
    position: relative;
    top: -2px;
}

.pull-right {
    float: right;
}

.dropdown-toggle {
    background: none !important;
    color: #fff !important;
    font-weight: 500;
    outline: none;
    border: none;
    width: 155px;
}

.main-header .navbar .dropdown-menu {
    box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
}

.main-header .dropdown-menu {
    position: absolute;
    border: none;
    border-radius: 0;
    margin-top: 0;
    z-index: 9999;
}

.header-d-menu {
    left: unset;
    right: 0;
}

.dropdown-item {
    font-weight: 500;
    color: #7e7e7e;
    background-color: #f7f7f7;
}

.dropdown-header {
    width: 100%;
    display: block;
    padding: 0.5rem 1rem;
    font-size: 15px;
    color: #000;
    font-weight: bold;
    cursor: default;
    border-bottom: 1px solid #eee;
}

.dropdown-header:hover {
    color: #000;
}

.card-box-shadow {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
}

.mw-140 {
    max-width: 140px;
}

.ships {
    display: flex;
    justify-content: end;
    margin-bottom: 30px;
}

.add-checkout {
    color: #fff;
    background-color: #000;
    border-color: #000;
    box-shadow: none;
    padding: 7px 14px;
    border-radius: 2px;
    font-size: 14px;
    cursor: pointer;
}

.section-header {
    font-size: 1.29rem;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0;
    font-size: .93rem;
    font-weight: 700;
}

.go-back-signin {
    margin-right: 0.5rem;
    position: relative;
    top: -1px;
    cursor: pointer;
}

@media (max-width: 560px) {
    .main-header .navbar-brand {
        width: 150px;
    }
}

@media (max-width: 560px) {
    .main-header .navbar-brand {
        margin: 0 20px 0 20px !important;
    }
}

@media (max-width: 576px) {
    .sign-in .modal-dialog {
        margin: 12%;
    }

    .mob-pr3 {
        padding-right: 3px !important;
    }

    .top-padding {
        padding: 94px 0 100px 0 !important;
    }

    .mob-mb-shop-prod {
        margin-bottom: 0.6rem !important;
    }

    .product-title {
        font-size: 1.7rem;
        top: 4px;
    }

    .subtitle {
        bottom: 1rem;
        font-size: 1rem;
    }

    .banner-img {
        height: 45vh !important;
    }

    .nav-link {
        color: #fff;
    }

    .btn-close {
        filter: contrast(0.1);
        opacity: 1;
    }
}

@media (max-width: 767px) {
    .main-header .sticky-area-wrap {
        height: 93.265px;
    }

    .signin-block {
        margin-bottom: 6rem;
    }
}

@media (min-width: 768px) {
    .signin-block {
        border-right: 1px solid #dcdcdc;
    }

    .reset-btn {
        margin-top: 3.8rem;
    }

    .signin-block,
    .signup-block {
        padding: 2rem !important;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .main-header .sticky-area-wrap {
        height: 102.023px;
    }

    .samples-container {
        padding: 0 2rem !important;
    }
}

@media (max-width: 989px) {
    .dropdown-toggle {
        width: auto !important;
    }
}

@media (min-width: 990px) {
    .sm-none {
        display: none !important;
    }

    .price-font {
        font-size: 20px;
    }
}

@media (max-width: 990px) {
    .offcanvas-body {
        flex-grow: initial;
        display: block !important;
    }

    .cart-user {
        display: none !important;
    }
}

@media (max-width: 991px) {
    .nav-link {
        color: #fff;
    }

    .btn-close {
        filter: contrast(0.1);
        opacity: 1;
    }

    .mob-header-pl {
        padding-left: 3rem !important;
    }

    .social-mob {
        position: fixed;
        bottom: 2.5rem;
        left: 1.5rem;
    }
}

@media (min-width: 1024px) {

    .reset-btn {
        margin-top: 5.3rem;
    }
}

@media (min-width: 1200px) {

    .reset-btn {
        margin-top: 3.8rem;
    }
}

@media (min-width: 992px) {
    .social-mob {
        display: none !important;
    }
}

@media (min-width: 991px) and (max-width: 1099px) {
    .nav-link {
        font-size: 12px;
        padding: 10px !important;
    }

    .main-header .navbar-brand {
        margin: 0 40px !important;
    }

    .main-header .navbar-brand .normal-logo {
        width: 80%;
        display: flex;
        margin: 0 auto;
    }

    .main-header .navbar .nav-link svg {
        font-size: 20px !important;
    }

    .main-header .navbar-right .number {
        right: 0 !important;
    }

    .dropdown-toggle {
        width: 145px;
    }
}

@media (min-width: 1100px) and (max-width: 1199px) {
    .nav-link {
        font-size: 12px;
        padding: 10px !important;
    }

    .main-header .navbar .nav-link svg {
        font-size: 20px !important;
    }

    .main-header .navbar-right .number {
        right: 0 !important;
    }

    .signin-block,
    .signup-block {
        padding: 3rem !important;
    }
}

@media (min-width: 1100px) and (max-width: 1419px) {

    .dropdown-toggle {
        width: 108px;
    }
}

@media (min-width: 1200px) {

    .signin-block,
    .signup-block {
        padding: 3rem !important;
    }
}

@media (min-width: 1300px) {

    .signin-block,
    .signup-block {
        padding: 6.875rem !important;
    }

    .signup-section {
        padding: 0 200px !important;
    }

    .reset-btn {
        margin-top: 5.2rem;
    }

    .reset-form {
        padding: 0 8rem !important;
    }

    .contact-img {
        height: 550px !important;
    }
}