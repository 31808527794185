body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', urbanist,
    sans-serif !important;
  font-weight: 500 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  color: #7e7e7e !important;
  text-align: left;
  background-color: #fff;
}

html,
body {
  overflow-x: hidden;
}

* {
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "FreightBigCmpPro";
  src: url("./fonts/FreightBigCmpProLight-Regular.otf") format("opentype");
}

@font-face {
  font-family: "OpenSansConsolidated";
  src: url("./fonts/OpenSans-CondLight.ttf") format("truetype");
}

@font-face {
  font-family: garamond;
  src: url("./fonts/CormorantGaramond-SemiBold.ttf") format('truetype');
}

@font-face {
  font-family: "Bauer-Bodoni";
  src: url("./fonts/Bauer\ Bodoni\ Regular.otf") format('truetype');
}