.order-card {
    width: 100%;
    font-size: 14px;
    overflow: hidden;
    transition: box-shadow .1s linear;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    position: relative;
    cursor: pointer;
    display: block;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .15);
    margin-bottom: 8px;
    border-radius: 4px;
}

.order-card:hover {
    z-index: 1;
    box-shadow: 0 1px 12px 2px #dbdbdb;
}

.img-section {
    width: 50px;
}

.display-block {
    display: block;
}

.no-orders {
    height: 400px;
    padding: 4rem;
    text-align: center;
    border: 1px solid #ccc;
    font-size: 1.5rem;
}

.no-orders-cover {
    margin-top: 8%;
}