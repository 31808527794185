.order-invoice {
    margin-top: 120px;
}

.order-details-sec {
    display: grid;
    justify-content: center;
    color: #000000;
    text-align: center;
}

.address-label {
    font-weight: 800;
    color: black;
}

.fs-14 {
    font-size: 14px;
}

.user-details {
    display: flex;
    justify-content: space-between;
}

.order-details-table thead {
    background: #000;
    color: #fff;
}

.order-details-table th {
    padding: 0.8rem;
    /* padding-left: 2rem; */
}

.order-details-table .data-row {
    border-bottom: 1px solid #ccc;
}

.order-details-table td {
    padding: 1rem;
}

.amount-label {
    color: #000;
    font-weight: 600;
}

.total-amt {
    font-size: 24px;
    color: #000;
    font-weight: 600;
    padding: 1rem 0.4rem !important;
}

.view-more-btn {
    width: 20%;
    margin: 0 auto;
}

.btn-section {
    display: inline-flex;
    width: 50%;
}

.go-backto-orders {
    position: absolute;
    font-size: 2rem;
    margin-left: -8px;
    cursor: pointer;
}

.order-sec-padding {
    padding: 4.875rem !important;
}

.text-align-end {
    text-align: end;
}

@media (max-width: 576px) {

    .user-details {
        display: block;
        margin: 0 2rem;
    }

    .total-amt {
        font-size: 15px;
        padding: 0.5rem 5px !important;
    }

    .btn-section {
        display: inline-flex;
        width: auto;
    }

    .go-backto-orders {
        margin-left: -1.5rem;
        margin-top: -0.4rem;
        font-size: 1.5rem;
    }

    .order-sec-padding {
        padding: 1.875rem !important;
    }

    .no-orders-cover {
        margin-top: 20%;
    }

    .no-orders {
        height: 200px !important;
        padding: 2rem !important;
        font-size: 0.8rem !important;
    }

    .img-section {
        width: 20px !important;
    }

    .order-card {
        font-size: 5px !important;
    }

    .order-invoice {
        margin-left: 30px !important;
        margin-right: 30px !important;
    }
}

@media (max-width: 778px) {

    .order-invoice {
        margin-left: 50px;
        margin-right: 50px;
    }
}

@media (min-width: 1200px) {

    .order-invoice {
        margin-left: 300px;
        margin-right: 300px;
    }
}