.heading-product {
    font-size: 50px;
    text-transform: uppercase;
}

.bottle-ml {
    font-weight: 600;
    color: #000000;
    margin-bottom: 8px;
    display: block;
}

.order-btn {
    margin: 0 7rem;
}

.line-h-btn {
    line-height: 1.6;
}

.product-detail-accordion .accordion-item {
    border: 0;
}

.product-detail-accordion .accordion-button {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid #eee !important;
    font-size: 24px;
    font-weight: 600;
}

.product-detail-accordion .accordion-button:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.product-detail-accordion .accordion-body {
    padding: 0 !important;
    color: #7e7e7e !important;
}

.product-detail-accordion .accordion-button:not(.collapsed) {
    background-color: transparent !important;
    box-shadow: 0 !important;
    color: #000 !important;
    outline: none !important;
}

.vdo-preview {
    border: 1px solid #ccc;
}

.pl-25 {
    padding-left: 25px;
}

.vdo-play-btn {
    position: absolute;
    top: 35%;
    left: 40%;
    font-size: 18px;
    z-index: 99;
    color: #ffffff;
}

.galleries-product .slider-nav .slick-current img,
.galleries-product .slider-nav .slick-current video {
    border: 1px solid #000;
}

.single-slider .slick-slide {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
}

.out-of-stock-label {
    font-size: 20px;
    color: #878787;
    font-weight: 400;
}

.sold-out-label {
    color: #212121;
    font-size: 36px;
    font-weight: 500;
}

.ml-1 {
    margin-left: 5px;
}

.supreme-sec {
    border-top: 1px solid #ccc;
    padding-top: 25px;
}

.supreme-btn {
    position: absolute;
    top: 38%;
    left: -20px;
}

.supreme-img {
    width: 200px;
    height: 300px;
}

.supreme-price-sec {
    position: relative;
    width: 450px;
    display: inline-block;
}

.comb-add-btn {
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;
    color: #000;
}

.fs-14 {
    font-size: 14px;
}

/* .supreme-add-btn {
    position: absolute;
    top: 2rem;
    left: 0rem;
} */

.img-index {
    position: absolute;
    bottom: -20px;
    right: 0;
    color: #fff;
    font-size: 13px;
}

.prev-img,
.next-img {
    position: fixed;
    top: 50%;
    font-size: 40px;
    color: white;
    opacity: 0.8;
    cursor: pointer;
    z-index: 999;
}

.object-cover {
    object-fit: cover;
}

.prev-img {
    left: 0;
}

.next-img {
    right: 0;
}

.prev-img:hover,
.next-img:hover {
    opacity: 1;
}

.img-title-suffix {
    position: relative;
    height: 100%;
}

.prod-titles {
    position: absolute;
    bottom: 15px;
    left: 0;
}

.close-img-btn {
    right: 5px;
    color: #fff;
    position: fixed;
    top: 0;
    border: 0;
    font-size: 1.5rem;
}

.imgs-popup .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.video-content .modal-content {
    display: contents;
}

.product-price-sec {
    align-items: center;
    display: flex;
    justify-content: center;
}

.add-cart-btn {
    position: absolute;
    left: 0;
    top: 2rem;
}

.preview-video::-internal-media-controls-overlay-cast-button {
    display: none;
}

#productVariants {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    column-gap: 12px;
    margin-bottom: 12px;
}

/* .productVariant{
 position: relative;
    border: 2px solid #CCCCCC;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 15px;
    padding: 8px 24px;
    height: 180px;
    max-width: 300px;   
    color:#000000
} */

.productVariant {
    position: relative;
    border: 2px solid #CCCCCC;
    /* border-radius: 12px; */
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 15px;
    padding: 8px 24px;
    height: 60px;
    max-width: auto;
}


.productVariant.active {
    border: 4px solid #000000;
}

/* .productVariantTitle {
    margin-top: 10px;
    font-weight: 700;
    font-size: 25px;
    font-family: Raleway,sans-serif!important;
    text-transform: uppercase;
    color:#000000;   
} */

.productVariantTitle {
    font-weight: 700;
    /* font-size: 22px; */
    font-family: Raleway,sans-serif!important;
    text-transform: uppercase;
    line-height: 100%;
    color:#120a0a
}

.productVariant--options-priceHolder {
    display: flex;
    flex-direction: column;
}
/* 
.productVariantSave {
    background: #F7DC5A;
    border-radius: 8px;
    color: #000000;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway,sans-serif!important;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: flex;
    align-self: end;
    width: 100px;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 8px;
} */

.productVariantSave {
    background: #000;
    border-radius: 8px;
    color: #fff;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway,sans-serif!important;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: flex;
    align-self: end;
    width: 91px;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 8px;
}
.productVariant--options-price {
    display: flex;
    align-items: flex-end;
}

/* .productVariantCompare {
    color: #F65B5B;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway,sans-serif!important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-decoration: line-through;
    padding-right: 5px;
} */


.productVariantCompare {
    color: gray;
    text-align: right;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway,sans-serif!important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    text-decoration: line-through;
    padding-right: 10px;
}


.productVariant div.empty {
    visibility: hidden;
    display: none;
}


/* .productVariantPrice {
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    line-height: 100%;
    margin-bottom: 2px;
} */

.productVariantPrice {
    display: flex;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    margin-bottom: 2px;
}

.productVariantLabel {
    color: #999999;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Raleway,sans-serif!important;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: .1px;
    padding-left: 4px;
}

.productVariant.active .productVariantBadge {
    top: -10px;
}

.productVariantBadge {
    position: absolute;
    transform: translate(-50%);
    top: -8px;
    left: 50%;
    padding: 4px 10px 2px;
    background-color: #000000;
    white-space: nowrap;
    font-weight: 700;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 24px;
}


/* .price-font{
    font-size: 30;
} */
/* Style */


@media (max-width: 576px) {
    .mt-15 {
        margin-top:15px !important
    }
    .supreme-img {
        width: 50px !important;
        height: 100px !important;
    }

    .mob-pt-10 {
        padding-top: 10px !important;
    }

    .mob-mb-10 {
        margin-bottom: 10px !important;
    }

    .product-desc {
        font-size: 8px;
        position: relative;
    }

    .mob-bottom {
        bottom: 25px;
    }

    .mob-order {
        order: 2;
    }

    .add-cart-btn {
        left: 0 !important;
        top: 0.5rem !important;
    }

    .fs-7 {
        font-size: 7px !important;
    }

    .fs-8 {
        font-size: 8px !important;
    }

    .fs-10 {
        font-size: 10px !important;
    }

    .fs-12 {
        font-size: 12px !important;
    }
    /* .fs-13 {
        font-size: 10px !important;
    } */

    .moreImages-sup img {
        max-width: 45px !important;
        max-height: 45px !important;
    }

    .img-title-suffix {
        font-size: 6px;
    }

    .supreme-btn {
        top: 28% !important;
        left: -3px !important;
    }

    /* .discount-badge {
        margin-left: 0 !important;
    } */

    .supreme-sec .supreme-header {
        font-size: 40px !important;
    }

    .supreme-sec .product-heading,
    .supreme-sec .sub-heading-product {
        font-size: 10px;
    }

    .prod-name {
        font-size: 10px;
    }

    .samples-container {
        padding: 0 1.5rem;
    }

    .bottle-ml {
        font-size: 25px !important;
    }

    .mob-mb-0 {
        margin-bottom: 0 !important;
    }

    .mob-mb-1 {
        margin-bottom: 0.5rem !important;
    }

    .galleries-product .slider-nav img,
    .galleries-product .slider-nav video {
        height: 103px !important;
    }
}

@media (min-width: 800px) {
    .cart-icon {
        font-size: 25px;
    }

    .supreme-btn {
        top: 35% !important;
        left: 3px !important;
    }
}

@media (max-width: 800px) {

    .slick-slide img,
    .slick-slide video {
        height: 420px;
    }

    .primary-summary-inner {
        position: static !important;
        width: auto !important;
    }

    .comb-add-btn {
        font-size: 12px;
    }

    .fs-14 {
        font-size: 10px;
        padding: 3px;
    }

    .supreme-btn {
        font-size: 6px !important;
        padding: 0 3px;
    }

    .supreme-price-sec {
        width: 140px;
        /* top: -18px; */
        font-size: 10px !important;
    }

    .supreme-price-sec .badge {
        font-size: 10px !important;
        margin-left: 2px !important;
    }

    .supreme-price-sec .actual-price {
        font-size: 10px !important;
    }

    .supreme-add-btn {
        top: 1.5rem;
    }

    .samples-container {
        padding: 0 1.5rem;
    }
}

@media (max-width: 991px) {

    .supreme-img {
        width: 100px;
        height: 200px;
    }

    .fs-14 {
        font-size: 10px;
        padding: 3px 5px;
    }

    .supreme-btn {
        font-size: 8px;
        padding: 0 3px;
    }

    .supreme-price-sec {
        width: 152px;
        /* top: -18px; */
        font-size: 10px !important;
    }

    .supreme-price-sec .badge {
        font-size: 10px !important;
        margin-left: 2px !important;
    }

    .supreme-price-sec .actual-price {
        font-size: 10px !important;
    }

    .supreme-add-btn {
        top: 1.2rem;
    }
}


@media (min-width: 1200px) {

    .single-slider .slick-slide {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 0 !important;
    }

    .single-slider .slick-track {
        height: auto !important;
    }

    .samples-container {
        padding: 0 3rem !important;
    }

}

@media (max-width: 1199px) {

    .single-slider {
        overflow: hidden;
        display: block !important;
    }

    .single-slider .slick-slide {
        padding-left: 0.3125rem !important;
        padding-right: 0.3125rem !important;
    }

    .slider-for .slick-active {
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
    }

    .slider-nav .slick-active {
        padding-top: 0 !important;
    }

    .slider-nav .slick-active:first-child {
        padding-left: 0 !important;
    }

    .slider-nav .slick-active:last-child {
        padding-right: 0 !important;
    }
}



