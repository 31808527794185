.vh-100-video {
    margin-top: 78px;
}

.padding-0 {
    padding: 0 !important;
}

.order-lg-2 video {
    height: 431px !important;
}

.banner-img {
    width: 100%;
    margin: 0 auto;
    display: block;
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-image: url('/public/images/banner.jpg');
    transition: 2s ease all;
    position: relative;
}

.product {
    position: relative;
    animation: myfirst 5s 2;
    animation-direction: alternate;
    animation-iteration-count: infinite;
}

.width-200 {
    width: 200px;
}

.video-cover {
    object-fit: cover;
}

.feedback-text h4,
.feedback-text h3 {
    font-family: 'FreightBigCmpPro';
    letter-spacing: .2em !important;
    font-size: 18px !important;
    text-transform: uppercase;
}

.feedback-text p:last-child {
    font-family: urbanist, sans-serif;
    font-size: .9375rem !important;
}

.fs-13 {
    font-size: 13px !important;
}

#discountBanner {
    z-index: 1031;
    width: 100%;
    text-align: center;
    background: #fff;
    color: #000;
    height: 23px;
}

#discountBanner span {
    cursor: pointer;
}

#discountBanner p {
    margin-bottom: 0;
}

.close-banner {
    position: absolute;
    right: 0;
    top: 0;
}

@keyframes myfirst {
    0% {
        left: 200px;
    }

    100% {
        left: 0px;
    }
}

.product-info:hover {
    transform: translateY(-10px);
    color: black;
}

.img-shop::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 1s;
}

.product-heading {
    font-size: 18px;
    margin: 0;
    font-weight: 800;
}

.sub-heading-product {
    font-size: 14px;
    margin: 0px 0 18px 0;
    display: block;
}

.color-white {
    color: #fff;
}

.brita-btn {
    background-color: black;
    padding: 8px 13px;
    color: #ffffff !important;
    font-size: 14px !IMPORTANT;
    text-decoration: none;
    border-radius: 0px;
    text-align: center;
    font-weight: 700;
    position: relative;
    bottom: 10px;
    display: table;
    margin: 0 auto;
}

.feedback-text {
    color: #fff !important;
}

.font-freightbig {
    font-family: FreightBigCmpPro;
    letter-spacing: .2em !important;
}

.try-btn-beauty {
    background-color: #000 !important;
    color: #ffffff !important;
    font-weight: 600;
}

.rounded {
    border-radius: 0px !important;
}

.insta-img img {
    height: 350px !important;
    object-fit: cover;
}

.insta-para {
    display: block;
    margin-top: 20px;
    text-align: center;
}

.insta-para:hover {
    text-decoration: underline;
}

.change-image-size img {
    /* max-width: 200px; */
    height: 240px;
    object-fit: contain;
}

.height-135 {
    height: 135px;
}

.heading-wrap {
    display: -webkit-box;
    max-width: 400px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

line-clamp-1 {
    -webkit-line-clamp: 1;
}

.line-clamp-2 {
    -webkit-line-clamp: 2;
}

.actual-price {
    margin-left: 8px;
    text-decoration: line-through;
    font-size: 14px;
    color: #878787;
}

.discount-percent {
    margin-left: 8px;
    color: #388e3c;
    font-size: 13px;
    letter-spacing: -.2px;
    font-weight: bold;
}

.close-btn {
    position: absolute;
    right: 2px;
    color: #000;
    font-size: 1.5rem;
    border: 0;
}

.img-text {
    position: absolute;
    color: #fff;
    font: 3vw/1.2 "Bauer-Bodoni";
    text-align: center;
    animation: fadeInAnimation 6s;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

.banner-txt {
    position: absolute;
    color: #fff;
    font: 2vw/1.2 "Bauer-Bodoni";
    text-align: center;
    left: 5%;
    top: 20%;
    text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
    animation: fadeInAnimation 2s;
}

.banner-txt1 {
    right: 10%;
    top: 15%;
}

.banner-txt2 {
    left: 10%;
    bottom: 24%;
}

@keyframes bannerZoom {
    0% {
        perspective: 100px;
        /* transform: scale(1); */
        opacity: 1;
    }

    100% {
        perspective: 1000px;
        /* transform: scale(5); */
        opacity: 0.2;
    }
}

@keyframes font-zoom {
    0% {
        perspective: 100px;
        transform: scale(1);
    }

    100% {
        perspective: 1000px;
        transform: scale(5);
    }
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/** The Keyframes
  --------------------------------------**/
@-webkit-keyframes image-zoom {
    0% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
        -webkit-transform-origin: 50% 16%;
        transform-origin: 50% 16%;
    }

    100% {
        -webkit-transform: scale(1.25) translateY(-15px);
        transform: scale(1.25) translateY(-15px);
        -webkit-transform-origin: top;
        transform-origin: top;
    }
}

@keyframes image-zoom {
    0% {
        -webkit-transform: scale(1) translateY(0);
        transform: scale(1) translateY(0);
        -webkit-transform-origin: 50% 16%;
        transform-origin: 50% 16%;
    }

    100% {
        -webkit-transform: scale(1.25) translateY(-15px);
        transform: scale(1.25) translateY(-15px);
        -webkit-transform-origin: top;
        transform-origin: top;
    }
}

/* @media (min-width: 850px) {
    .img-text {
        margin-left: 30%;
        margin-top: 18%;
        width: 600px;
    }
} */

@media (max-width: 576px) {
    .banner-txt {
        font: 5vw/1.2 "Bauer-Bodoni";
        top: auto !important;
        left: 26%;
        bottom: 0;
    }

    .banner-txt1 {
        font: 4.5vw/1.2 "Bauer-Bodoni";
        right: 5%;
        top: 20%;
    }

    .banner-txt2 {
        font: 5vw/1.2 "Bauer-Bodoni";
        left: 3%;
        bottom: 28%;
    }

    .mobile-padding-shop {
        padding: 20px 0 0 0 !important;
    }

    .mobile-no-mb {
        margin-bottom: 15px !important;
    }

    .mobile-no-mb .mb-8 {
        margin-bottom: 0 !important;
    }

    .mob-pt-1 {
        padding-top: 1rem !important;
    }

    .brita-btn {
        bottom: 28px !important;
    }

    .mob-p-30 {
        padding: 30px;
    }

    .mob-prod-imgs {
        padding: 0 30px;
    }

    .mob-prod-imgs .slick-slide img,
    .mob-prod-imgs .slick-slide video {
        height: 350px;
    }

    .mob-display-sketches {
        display: flex;
        flex-direction: row;
    }

    .mob-display-sketches .card-img {
        width: auto;
    }

    .mob-display-sketches .card-img img {
        height: 160px;
    }

    .mob-display-sketches .card-body {
        padding-top: 2rem;
    }

    .mob-display-sketches .cart-text {
        font-size: 14px;
    }

    .insta-img img {
        height: 180px !important;
    }

    .insta-imgs {
        margin-bottom: 8px;
    }

    .mob-pr {
        padding-right: 5px;
    }

    .mob-pl {
        padding-left: 5px;
    }
    .mob-pt-0 {
        padding-top: 0 !important;
    }
}

@media (max-width: 800px) {
    .banner-img img {
        margin: 0 auto;
        display: block;
        height: 68vh;
        object-fit: cover;
    }
}